.ant-message-notice-content {
  box-shadow: none !important;
  background-color: transparent !important;

  .ant-message-custom-content {
    width: 100%;
    padding: 8px 15px;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: left;
    &.ant-message-success {
      background: #eff7f3;
      border: 1px solid #40cd87;
      box-sizing: border-box;
      border-radius: 4px;
      > span {
        color: #40cd87;
      }
      > div {
        color: #40cd87;
        margin-left: 30px;
        :hover {
          cursor: pointer;
        }
      }
    }
    &.ant-message-error {
      background: #faf2f1;
      border: 1px solid #ed6868;
      > span {
        color: #ed6868;
      }
      > div {
        color: #ed6868;
        margin-left: 30px;
        :hover {
          cursor: pointer;
        }
      }
    }
    &.ant-message-error {
      background: #faf2f1;
      border: 1px solid #ed6868;
      > span {
        color: #ed6868;
      }
      > div {
        color: #ed6868;
        margin-left: 30px;
        :hover {
          cursor: pointer;
        }
      }
    }
    &.ant-message-warning {
      background: #f3f9f6;
      border: 1px solid #ebc40e;
      height: 80px;
      > span {
        color: #ebc40e;
      }
      > div {
        color: #ebc40e;
        margin-left: 30px;
        :hover {
          cursor: pointer;
        }
      }
    }
    > div {
      float: right;
      cursor: pointer;
    }
    > span {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      &.anticon {
        display: none;
      }
    }
  }
}

@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;