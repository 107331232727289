¨:selection {
    color: #fff;
    background: #4e76a5 !important;
}

html, 
body {
    height: 100vh;
}


.ant-layout {
    background: #d8dee5;
}

.ant-switch-checked{
    background-color: #272e3a !important;
}


 .ant-spin-dot-item {
    background-color: #272e3a !important;
} 

.ant-page-header-heading-title {
    color: #272e3a !important;
}