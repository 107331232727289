.rk-page-container {
  width: 100%;
}

.rk-page-header {
  display: flex;
  padding: 1rem 14px !important;
  background-color:#FFF;
  border-bottom: 1px solid #dde2ed !important;
  z-index: 98;
  margin: 0px auto !important;
  background-color: #FFF !important;
  .ant-page-header-heading{
    width: 100%;
    .ant-page-header-heading-extra{
      margin-left: auto;
    }
  }

}

.rk-page-content {
  width: 100%;
  padding: 1vh 2vh;
  > .ant-form {
    width: 100%;
    height: 1024px;
    flex: 1;
    overflow-y: scroll;
    margin: 1vh auto;
    justify-content: center;
    > .form-card {
      width: 650px;
    }
  }
}

.ant-input-search-button {
  padding: 9px !important;
  height: 45px !important;
}

.ant-input-affix-wrapper-lg {
  padding: 9px 11px !important;
  :focus,
  :hover,
  :active {
    border: none;
    box-shadow: none;
  }
  ::selection {
    background: none !important;
    border: none;
    box-shadow: none;
  }

  &.ant-input-affix-wrapper-input-with-clear-btn {
    .ant-input-suffix {
      div {
        display: none;
      }
    }
  }
  .ant-input-affix-wrapper-focused {
    :not(:hover) {
      border: none !important;
    }
  }
}

.ant-input-search-button {
  display: none;
}

// SD

@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;