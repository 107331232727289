// .ant-list-item {
//   border-bottom: none !important;
//   padding: 4px 0 !important;
//   > .ant-list-item-meta {
//     padding: 15px 20px;
//     width: 522px;
//     height: 60px;
//     background: white;
//     border: 1px solid #dde2ed;
//     box-sizing: border-box;
//     border-radius: 4px;

//     .ant-list-item-meta-title {
//       line-height: 29px;
//     }

//     &.rk-list-checkout {
//       background: #dde2ed !important;
//     }
//   }
// }

.rk-list-title {
  font-size: 16px !important;
  color: black !important;
  font-weight: 700 !important;
}

.rk-list-description {
  font-size: 14px !important;
  color: #77787e !important;
  font-weight: 600 !important;
}

@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;