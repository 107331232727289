.report-container {
  margin-top: 10vh !important;
  margin: 10px auto;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  background: #fafafa;
  width: calc(100vw - 300px);
  min-height: 550px;
  width: ~"calc(100vw - 300px)";
  min-width: 800px;
  display: flex;
  > div {
    padding: 0vh 2vh;
    min-height: 550px;
    flex-direction: column;
  }

  .legend {
    width: 12%;
    height: 500px;
    flex-shrink: 0;
    padding-right: 20px;
    padding-top: 30px;
    overflow-y: scroll;
    margin-left: -95px;
    .body {
      height: 385px;

      .box {
        width: 15px;
        height: 15px;
        min-width: 15px;
        min-height: 15px;
        border: 1px solid black;
      }
      .text {
        padding-left: 4px;
        padding-top: 0px;
        line-height: 15px;
      }
    }
  }
}

@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;