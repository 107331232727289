@import "~antd/dist/antd.less";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap');

* {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @primary;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
    }
  }
}

html,
body,
#root {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
}


@media only screen and (min-width: 1440px) {
  // .App {
  //   min-width: 1440px;
  //   width: auto;
  //   max-width: 1440px;
  //   margin: 0 auto !important;
  // }
}


p {
  margin-bottom: 0px !important;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #dde2ed;
}

.rk-layout-content {
  margin-top: 64px !important;
}

.rk-form {
  flex: 1;
  margin: 2vh 10vw;
  justify-content: center;
  height: 100hv;
}

.rk-button-primary {
  height: 44px;
  width: 147px;
  font-weight: 600;
  font-size: 14px;
}

.ant-table-thead {
  tr {
    th {
      color: @primary;
      font-style: normal !important;
      font-weight: 600 !important;
      font-size: 14px !important;
    }
  }
}

.rk-menu-dropdown {
  padding: 0 !important;
  position: relative;
  right: 60px;
  border-radius: 4px;
  width: 160px;
  .ant-dropdown-menu-item-aqctive {
    background: #dde2ed !important;
  }
  .rk-menu-dropdown-item {
    width: 100%;
    height: 48px;
    > span {
      width: 90%;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dde2ed !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;

      &:hover {
        font-weight: 600;
      }
    }
  }
}

.disabled-row {
  color: #c3c5cc !important;
  h4.ant-list-item-meta-title {
    color: #c3c5cc !important;
  }
  div.ant-list-item-meta-description {
    span {
      color: #c3c5cc !important;
    }
  }
  
}

.ant-descriptions-item-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

// table {
//   margin: auto;
//   td,
//   th {
//     border: 1px solid #f0f0f0 !important ;
//   }
//   tr > th {
//     font-weight: bold;
//     background: #fafafa;
//   }
// }

.rk-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

tr {
  .ant-table-cell-row-hover {
    cursor: pointer;
  }
  td {
    padding: 10px;
  }
}

.rk-button-group {
  display: flex;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background-color: #fff;
  text-align: right;
  gap: 5px;
  flex-direction: row-reverse;
}

.rk-descriptions-items {
  .ant-descriptions-item-label {
    line-height: 44px;
  }
}

.rk-tag {
  height: 30px;
  padding: 4px 12px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  > span {
    margin-left: 10px;
  }
}


.ant-spin-spinning{
  width: 100%;
}

.ant-form-item{
  margin-bottom: 0 !important;
}

.ant-input-lg {
  padding: 10px 15px;
  font-size: 16px;
}
.ant-form-item-label > label {
  font-weight: 500;
  font-size: 16px;
}


.ant-descriptions-item-container .ant-descriptions-item-label {
  display: inline-flex;
  align-items: center;
}

.ant-alert {
  &-info {
    padding: 9px;
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    gap: 5px;
    .ant-alert-icon {
      color: #166DC2;
    }
    .ant-alert-content {
      border-left: 2px solid #1C8AF5;
      padding-left: 10px;
        .ant-alert-message{
          color: #004085;
        }
    }
  }
  &-icon {
    font-size: 15px; 
  }
}


.ant-btn {
  &-primary{
    > span {
      font-size: 14px;
    }
  }
}
.rk-tag {
  height: 30px;
  padding: 4px 12px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  > span {
    margin-left: 10px;
  }
}


.ant-tabs-nav-wrap {
  justify-content: flex-start !important; 
  padding: 0px 24px !important; 
}
.ant-tabs-content-holder {
  padding: 0px 24px !important 
}
@davy-grey: #555555;@very-light-gray: #e3e3e3;@font-family: Roboto;@primary: #0f1c30;@primary-1: #0f1c3020;@primary-color-hover: #0f1c3060;@primary-color-active: #0f1c3060;@primary-color-outline: #0f1c3060;@primary-lightest: lighten(@primary, 30%);@primary-lighter: lighten(@primary, 10%);@primary-dark: darken(@primary, 8%);@primary-darkest: darken(@primary, 10%);@secondary: rgb(79, 93, 117);@secondary-lightest: lighten(@secondary, 60%);@secondary-lighter: lighten(@secondary, 35%);@secondary-darker: darken(@secondary, 8%);@secondary-darkest: darken(@secondary, 10%);@text-color: @primary;@text-color-disabled: @secondary-lighter;@button-color: @secondary-lightest;@button-color-disabled: @secondary-lighter;@layout-header-background: #0f1c30;@primary-color: @primary;@processing-color: @primary-color;@normal-color: #d9d9d9;@white: #fff;@link-color: @primary;@text-color-secondary: @secondary;@border-radius-base: 4px;@border-color-base: #DDE2ED;@secondary-hover-color: #7283a0;@mentions-dropdown-menu-item-hover-bg: rgb(114, 131, 160);@menu-item-active-bg: #95a1b850;@input-color: @text-color;@input-hover-border-color: #e2e3ea;@box-shadow-base: 0 1px 1px #e2e3ea75 inset, 0 0 8px #e2e3ea06;